import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import socket from "../../socket";

const Join: React.FunctionComponent<any> = ({ setGameCode }) => {
  const [inputVal, setInputVal] = useState("");
  const joinGame = (code: string) => {
    socket.emit("join-game", code);
    setGameCode(code);
  };

  return (
    <>
      <TextField
        label="Room Name"
        variant="outlined"
        value={inputVal}
        onChange={(event) => setInputVal(event.target.value)}
      />

      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => joinGame(inputVal)}
      >
        Join Game
      </Button>
    </>
  );
};

export default Join;
