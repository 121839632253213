import React from "react";
import Typography from "@material-ui/core/Typography";

const Hinting: React.FunctionComponent<{ word?: string }> = ({ word }) => {
  if (!word) {
    return null;
  }
  return <Typography variant="h3">{word}</Typography>;
};
export default Hinting;
