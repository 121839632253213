import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Game from "./components/Game";
import Join from "./components/Join";

function App() {
  const [gameCode, setGameCode] = useState<string>();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh", textAlign: "center" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        style={{ height: "200px" }}
        justifyContent="space-between"
      >
        <Typography variant="h3" component="h1" gutterBottom>
          What Am I?
        </Typography>
        {!gameCode && <Join setGameCode={setGameCode} />}
        {gameCode && <Game />}
      </Box>
    </Grid>
  );
}

export default App;
