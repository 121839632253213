import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import socket from "../../socket";

const generateWord = () => {
  socket.emit("next-word");
};

const useTrackAnswer = () => {
  const [yesCount, setYesCount] = useState(0);
  const [noCount, setNoCount] = useState(0);
  const handleYes = () => {
    generateWord();
    setYesCount(yesCount + 1);
  };
  const handleNo = () => {
    generateWord();
    setNoCount(noCount + 1);
  };
  const score = `${yesCount}/${yesCount + noCount}`;
  socket.emit("score", score);

  return {
    handleNo,
    handleYes,
  };
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const Guessing: React.FunctionComponent = () => {
  const { handleYes, handleNo } = useTrackAnswer();

  const classes = useStyles();
  return (
    <Box>
      <Button
        size="large"
        variant="outlined"
        color="primary"
        onClick={handleYes}
        className={classes.margin}
      >
        Correct
      </Button>
      <Button
        size="large"
        variant="outlined"
        color="secondary"
        onClick={handleNo}
        className={classes.margin}
      >
        Pass
      </Button>
    </Box>
  );
};

export default Guessing;
