import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Hinting from "../Hinting";
import Guessing from "../Guessing";
import socket from "../../socket";

enum Status {
  Guessing,
  Hinting,
  Waiting,
}

const useGameInfo = () => {
  const [status, setStatus] = useState<Status>(Status.Waiting);
  const [word, setWord] = useState<string>();

  useEffect(() => {
    socket.on("word", (currentWord: string) => {
      setStatus(Status.Hinting);
      setWord(currentWord);
    });
    socket.on("ended", () => {
      setStatus(Status.Waiting);
    });
  }, []);
  const startGame = () => {
    socket.emit("start-game");
    setStatus(Status.Guessing);
  };

  return { status, word, startGame };
};

const useGameTimer = () => {
  const [secondsLeft, setSecondsLeft] = useState<Number>();
  useEffect(() => {
    socket.on("secondsLeft", (seconds: Number) => {
      setSecondsLeft(seconds);
    });
  }, []);
  return secondsLeft;
};

const useGameScore = () => {
  const [gameScore, setGameScore] = useState<String>();
  useEffect(() => {
    socket.on("score", (score: String) => {
      setGameScore(score);
    });
  }, []);
  return gameScore;
};

const InnerGame: React.FunctionComponent = () => {
  const { status, startGame, word } = useGameInfo();
  switch (status) {
    case Status.Waiting:
      return (
        <>
          <Button variant="contained" color="primary" onClick={startGame}>
            Start Game
          </Button>
        </>
      );
    case Status.Guessing:
      return <Guessing />;
    case Status.Hinting:
      return <Hinting word={word} />;
  }
};

const ScoreOrTime: React.FunctionComponent = () => {
  const gameScore = useGameScore();
  const secondsLeft = useGameTimer();

  if (secondsLeft) {
    return <>Time left: {secondsLeft}</>;
  }
  if (gameScore) {
    return <>Score: {gameScore}</>;
  }
  return null;
};

const Game: React.FunctionComponent = () => {
  return (
    <>
      <Typography variant="h5" component="p">
        <ScoreOrTime />
      </Typography>
      <InnerGame />
    </>
  );
};

export default Game;
